import {
    MyAccountQuery as SourceMyAccountQuery
} from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Rapelli/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    // TODO implement logic
    getCreateAccountMutation(options) {
        const { customer, password, orderID } = options;

        return new Field('createCustomer')
            .addArgument('input', 'CustomerInput!', { ...customer, password, orderID })
            .addField(this._getCustomerField());
    }

    /**
     * New additional fields to revoke account token.
     */
    getRevokeAccountToken() {
        return new Field('revokeCustomerToken')
            .addFieldList(this.getRevokeAccountTokenFields());
    }

    getRevokeAccountTokenFields() {
        return [
            'result'
        ];
    }

    getResetPasswordMutation(options) {
        const {
            id, token, password, password_confirmation
        } = options;

        return new Field('s_resetPassword')
            .addArgument('id', 'String!', id)
            .addArgument('token', 'String!', token)
            .addArgument('password', 'String!', password)
            .addArgument('password_confirmation', 'String!', password_confirmation)
            .addField('status');
    }

    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company',
            this._getRegionField()
        ];
    }
}

export default new MyAccountQuery();
