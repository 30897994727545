import CheckoutPayment from 'Component/CheckoutPayment';

import AdyenCC from '../component/AdyenCC';
import { ADYEN_CC } from '../component/AdyenCC/AdyenCC.config';
import AdyenHPP from '../component/AdyenHPP';
import { ADYEN_HPP, ADYEN_KLARNA, ADYEN_TWINT } from '../component/AdyenHPP/AdyenHPP.config';

export function renderOrderButtonLabel(paymentMethodType) {
    switch (paymentMethodType) {
    case 'adyen_hpp_twint':
        return __('Pay with Twint');
    case 'adyen_hpp_klarna':
        return __('Pay with Klarna');
    default:
        return __('Complete order');
    }
}

export function renderOrderButton(isAdvanceSelected, paymentMethodId, completeOrderButtonDisabled) {
    if (isAdvanceSelected) {
        /* Bug workaround: Twint has its own place order button, but CheckoutBillingContainer.plugin.js initializeAdyen()
        looks for this button's class in the DOM directly on change, it should therefore still be rendered but hidden */
        const hideButton = (paymentMethodId === 'adyen_hpp_twint') ? 'hideButton' : '';
        const label = renderOrderButtonLabel(paymentMethodId);
        return (
            <div block="Checkout" elem="StickyButtonWrapper" className={ `${hideButton} ${paymentMethodId}` }>
                <button
                  type="submit"
                  block="Button"
                  disabled={ completeOrderButtonDisabled }
                  mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { label }
                </button>
            </div>
        );
    }

    return null;
}

const renderPayment = (args, callback, instance) => {
    const [method] = args;
    const {
        selectPaymentMethod,
        selectedPaymentCode,
        orderButtonIsDisabled,
        orderButtonIsVisible,
        selectedMethodType
    } = instance.props;

    const { code, type = '' } = method;
    const isSelected = (selectedMethodType === type);
    const isAdvanceSelected = !isSelected ? isSelected : selectedPaymentCode === code;
    const paymentMethodId = type ? `${code}_${type}` : code;
    const renderAdyenForm = (code === ADYEN_CC && isAdvanceSelected) ? <AdyenCC /> : null;
    const completeOrderButtonDisabled = (orderButtonIsDisabled || !orderButtonIsVisible);

    return (
        <>
            <CheckoutPayment
              key={ paymentMethodId }
              isSelected={ isAdvanceSelected }
              method={ method }
              onClick={ selectPaymentMethod }
            />
            { renderAdyenForm }
            { renderOrderButton(isAdvanceSelected, paymentMethodId, completeOrderButtonDisabled) }
        </>
    );
};

export function renderAdyenHPP() {
    const { paymentMethods, selectedMethodType } = this.props;
    const method = paymentMethods.find((singleMethod) => {
        if (singleMethod.type === selectedMethodType) {
            return singleMethod;
        }
    });

    return (
        <AdyenHPP
          key="adyen_hpp_twint_place_order"
          method={ method }
        />
    );
}

export const paymentRenderMap = (originalMember, instance) => ({
    ...originalMember,
    [ADYEN_HPP]: renderAdyenHPP.bind(instance),
    [ADYEN_TWINT]: renderAdyenHPP.bind(instance),
    [ADYEN_KLARNA]: renderAdyenHPP.bind(instance)
});

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPayment,
            paymentRenderMap
        }
    }
};
