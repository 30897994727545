import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import Form from 'Component/Form';

import { b2bCompanySingleRoleType, b2bRoleFormPermissionsType } from '../../type/B2bCompany';

import './CompanyRolesEditForm.style';

/** @namespace Screenpages/b2bcompany/Component/CompanyRolesEditForm/Component/CompanyRolesEditFormComponent */
export class CompanyRolesEditFormComponent extends PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        handleSubmitSuccess: PropTypes.func.isRequired,
        handleSubmitError: PropTypes.func.isRequired,
        isFormSubmitting: PropTypes.bool.isRequired,
        handleOptionChange: PropTypes.func.isRequired,
        handleDeleteRole: PropTypes.func.isRequired,
        roleData: b2bCompanySingleRoleType,
        formPermissions: b2bRoleFormPermissionsType
    };

    static defaultProps = {
        roleData: {},
        formPermissions: []
    };

    renderEditRolesForm() {
        const {
            handleSubmit, handleSubmitSuccess, handleSubmitError, isFormSubmitting
        } = this.props;

        return (
            <Form
              mix={ { block: 'CompanyRolesEditForm', elem: 'Form' } }
              key="companyroleseditform"
              onSubmit={ handleSubmit }
              onSubmitSuccess={ handleSubmitSuccess }
              onSubmitFail={ handleSubmitError }
            >
              { this.renderRoleNameField() }
              { /* <div block="CompanyRolesEditForm" elem="QuotePermissions">
                  <span>
                    { __('Quote Permissions') }
                  </span>
                  { this.renderOptionField(__('Request, Edit, Delete'), 'quoterequesteditdelete') }
                  { this.renderOptionField(__('Checkout with quote'), 'quotecancheckout') }
                  { this.renderOptionField(__('View quotes of subordinate users'), 'quoteviewsubordinate') }
              </div> */ }
              <div block="CompanyRolesEditForm" elem="CompanyPermissions">
                  <span>{ __('Company Management') }</span>
                  { this.renderOptionField(__('Edit Company Profile'), 'companyeditprofile') }
                  { /* { this.renderOptionField(__('Edit Company Legal Address'), 'companyeditlegaladdress') } */ }
              </div>
              <div block="CompanyRolesEditForm" elem="CompanyUserPermissions">
                  <span>{ __('Company User Management') }</span>
                  { this.renderOptionField(__('Manage Roles'), 'companyusermanageroles') }
                  { this.renderOptionField(__('Manage Users'), 'companyusermanageusers') }
              </div>
              <button
                block="Button"
                disabled={ isFormSubmitting }
              >
                  <span>{ __('Save role') }</span>
              </button>
            </Form>
        );
    }

    renderRoleNameField() {
        const { roleData: { role_name } = '' } = this.props;

        return (
          <div block="CompanyRolesEditForm" elem="RoleName">
            <Field
              type="text"
              label={ __('Role Name') }
              name="rolename"
              id="rolename"
              validation={ ['notEmpty'] }
              value={ role_name }
            />
          </div>
        );
    }

    renderOptionField(label, name) {
        const { handleOptionChange, formPermissions } = this.props;
        return (
          <Field
            type="checkbox"
            label={ label }
            name={ name }
            id={ name }
            value={ name }
            onChange={ handleOptionChange }
            checked={ !!formPermissions[name] }
          />
        );
    }

    renderDeleteBtn() {
        const { handleDeleteRole, roleData: { role_id } = 0 } = this.props;
        if (role_id > 0) {
            return (
              <button
                block="CompanyRolesEditForm"
                elem="DeleteBtn"
                onClick={ handleDeleteRole }
              >
                <span>
                  { __('Delete Role') }
                </span>
              </button>
            );
        }

        return null;
    }

    render() {
        return (
            <div block="CompanyRolesEditForm">
                { this.renderEditRolesForm() }
                { this.renderDeleteBtn() }
            </div>
        );
    }
}

export default CompanyRolesEditFormComponent;
